import { createContext, useContext, useEffect, Fragment } from 'react';
// import useBrandConfigStore from './store/useBrandConfigStore.store';
import { gradientList, updateTheme } from '../utils/color.utils';
// import { gradientList, updateTheme } from '@/utils/color.utils';
import themeConfig from '../public/data/themecolor.json';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
	// const { themeConfig } = useBrandConfigStore((state) => ({
	// 	themeConfig: state.themeConfig
	// }));

	const { container, buttons, navbar, footer, gradientColor, icon } = themeConfig;
	const brandGradientColor = gradientColor?.default;
	const brandIconGradientColor = icon?.nonselected;
	const defaultColor = gradientList['gold'];

	useEffect(() => {
		const themeConfig = {
			/* brand setting color */
			'container-bg-primary': container?.backgroundColor?.primary ?? '#16161a',
			'container-bg-secondary': container?.backgroundColor?.secondary ?? '#72757e',
			'container-bg-tertiary': container?.backgroundColor?.tertiary ?? '#28282b',
			'container-bg-accent': container?.backgroundColor?.accent ?? '#2d334a',
			'container-bg-accent2': container?.backgroundColor?.accent2 ?? '#7f5af0',
			'container-bg-accent3': container?.backgroundColor?.accent3 ?? '#7f5af0',
			'container-color-secondary': container?.colors?.secondary ?? '#7f5af0',
			'container-color-accent': container?.colors?.accent ?? '#000000',
			'container-color-primary': container?.colors?.primary ?? '#fffffe',
			'button-primary': buttons?.primary ?? '#72b4ab',
			'button-danger': buttons?.danger ?? '#ff0000',
			'button-secondary': buttons?.secondary ?? '#ffff00',
			'navbar-bg-primary': navbar?.backgroundColor?.primary ?? '#242629',
			'navbar-color-primary': navbar?.colors?.primary ?? '#fff',
			'navbar-color-secondary': navbar?.colors?.secondary ?? '#00121d',
			'navbar-wallet-color': navbar?.walletColor ?? '#929292',
			'footer-bg-primary': footer?.backgroundColor?.primary ?? '#242629',
			'footer-color-primary': footer?.colors?.primary ?? '#fff',
			'footer-color-secondary': footer?.colors?.secondary ?? '#94a1b2',
			/* gradient color */
			'primary-color-s':
				gradientList[brandGradientColor]?.primaryColor ?? defaultColor.gradientColor,
			'secondary-color-s':
				gradientList[brandGradientColor]?.primaryColor ?? defaultColor.gradientColor,
			'tertiary-color-s':
				gradientList[brandGradientColor]?.primaryColor ?? defaultColor.gradientColor,
			// gradient
			'primary-gradient-color-s':
				gradientList[brandGradientColor]?.gradientColor ?? defaultColor.gradientColor,
			'secondary-gradient-color-s':
				gradientList['carbon']?.gradientColor ?? defaultColor.gradientColor,
			'tertiary-gradient-color-s':
				gradientList['platinum']?.gradientColor ?? defaultColor.gradientColor,
			'icon-gradient-color-s':
				gradientList[brandIconGradientColor]?.gradientColor ?? defaultColor.gradientColor,
			// static
			'primary-gradient-static-color-s':
				gradientList[brandGradientColor]?.primaryGradientStatic ??
				defaultColor.primaryGradientStatic,
			'secondary-gradient-static-color-s':
				gradientList['carbon']?.primaryGradientStatic ?? defaultColor.primaryGradientStatic,
			'tertiary-gradient-static-color-s':
				gradientList['platinum']?.primaryGradientStatic ?? defaultColor.primaryGradientStatic
		};
		updateTheme(themeConfig);
	}, []);

	return (
		<ThemeContext.Provider value={{}}>
			<Fragment>{children}</Fragment>
		</ThemeContext.Provider>
	);
};

export const useThemeProvider = () => useContext(ThemeContext);
