const appRoutes = {
	HOME: '/',
	REGISTER: '/register/',
	ARTICLE: '/a/',
	PROMOTION: '/promotion/',
	GAMES: '/category/',
	GAMES_ROUTES: {
		// LOTTERY: '/#lottery',
		// CARD: '/#card-game',
		// SPORT: '/#sports',
		LOTTERY: '/category/lottery/',
		CARD: '/category/cards/',
		SPORT: '/category/sports/',
		SLOT: '/category/slots/',
		CASINO: '/category/casino/',
		FISHING: '/category/fishing/',
		COCKFIGHT: '/category/cockfight/',
		OTHER: '/category/others/'
	},
	PROFILE: '/account/profile/information/',
	ACCOUNT_ROUTES: {
		PROFILE: '/account/profile/information/',
		WALLET_ROUTES: {
			HISTORY: {
				BASE: '/account/wallet/history/',
				DEPOWD: '/account/wallet/history/?transactionType=DEPOWD',
				SWAP: '/account/wallet/history/?transactionType=SWAP',
				GAMETRANSFER: '/account/wallet/history/?transactionType=GAMETRANSFER',
				QUERY_NAME: 'transactionType',
				QUERY: {
					DEPOWD: 'DEPOWD',
					SWAP: 'SWAP',
					GAMETRANSFER: 'GAMETRANSFER'
				}
			},
			DEPOSIT: '/account/wallet/deposit/',
			WITHDRAW: '/account/wallet/withdraw/',
			SWAP: '/account/wallet/swap/',
			ACCOUNT: '/account/wallet/account/'
		}
	},
	BETREPORT: '/account/report/',
	REFERRAL: '/account/referral/',
	REWARD: '/account/reward/bonus/',
	// PLAY: '/play/',
	PLAY: {
		INDEX: '/play/',
		GAME: '/play/game/',
		LAUNCH_GAME: '/play/launch-game/'
	},
	PROVIDER: '/providers/',
	WINNING_NUMBERS: '/winning-numbers/',
	FAQ: '/faq/',
	UNKNOWN: '*'
};

export default appRoutes;
