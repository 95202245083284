import { create } from 'zustand';
import banners from '../../public/data/banners.json';

interface HomeBannerDetail {
	img: string;
	redirect: string;
	order: number;
}

interface HomeBanner {
	mobile: HomeBannerDetail[];
	desktop: HomeBannerDetail[];
}

interface RegisterBannerDetail {
	img: string;
	redirect: string;
	order: number;
}

interface Registerbanner {
	mobile: RegisterBannerDetail[];
	desktop: RegisterBannerDetail[];
}

interface CategoryBannerDetail {
	slots: string;
	cards: string;
	sports: string;
	fishing: string;
	casino: string;
	lottery: string;
	cockfight: string;
	others: string;
}

interface CategoryBanner {
	mobile: CategoryBannerDetail;
	desktop: CategoryBannerDetail;
}

interface PopupBanner {
	img: string;
	isActive: string;
	redirect: string;
	type: string;
}

interface PromotionBanner {
	img: string;
	redirect: string;
}

// Define the state structure with types
interface BannerStoreState {
	homeBanner: HomeBanner;
	registerBanner: Registerbanner;
	categoryBanner: CategoryBanner;
	promotionBanner: Record<string, PromotionBanner>;
	popupBanner: Record<string, PopupBanner>;
}

// Define the actions available in the store
interface BannerStoreActions {
	// initBanners: () => void;
	// clearAllBanners: () => void;
}

// Combine state and actions for the store type
type UseBannerStore = BannerStoreState & BannerStoreActions;

// Create the store with Zustand
const useBannerStore = create<UseBannerStore>(() => ({
	homeBanner: banners.home,
	registerBanner: banners.register,
	categoryBanner: banners.category,
	promotionBanner: banners.promotion,
	popupBanner: banners.popup
	// Actions to modify the state
	// initBanners: () => {
	// 	set({
	// 		homeBanner: banners.home,
	// 		registerBanner: banners.register,
	// 		categoryBanner: banners.category,
	// 		promotionBanner: banners.promotion,
	// 		popupBanner: banners.popup
	// 	});
	// },
	// clearAllBanners: () =>
	// 	set({
	// 		homeBanner: {
	// 			mobile: [],
	// 			desktop: []
	// 		},
	// 		registerBanner: {
	// 			mobile: [],
	// 			desktop: []
	// 		},
	// 		categoryBanner: {
	// 			mobile: {},
	// 			desktop: {}
	// 		},
	// 		promotionBanner: {},
	// 		popupBanner: {}
	// 	})
}));

export default useBannerStore;
