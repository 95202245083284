import { useMemo } from 'react';
import Image from 'next/image';
// import Link from 'next/link';

import { customImageLoader } from '@/helpers/custom-image-loader';
import useBannerStore from '@/context/store/useBannerStore.store';
import useWindowHook from '@/hooks/useWindowHook.hook';
import useRouterHook from '@/hooks/useRouterHook.hook';

export const CategoryBanner = () => {
	const { router } = useRouterHook();
	const { isMobile } = useWindowHook();
	const { categoryBanner } = useBannerStore((state) => ({
		categoryBanner: state.categoryBanner
	}));

	const banner = useMemo(() => {
		const { category } = router.query;

		return isMobile
			? categoryBanner.mobile?.[`${category}`]
			: categoryBanner.desktop?.[`${category}`];
	}, [isMobile, router.query]);

	if (!banner) {
		return null;
	}

	return (
		<>
			<div className="relative flex items-center justify-center w-full aspect-2/1 lg:aspect-[3/1] max-h-[600px] bg-black">
				<Image
					src={banner}
					loader={customImageLoader}
					layout="fill"
					objectFit="contain"
					priority
					unoptimized
				/>
			</div>
		</>
	);
};
