import React, { useEffect } from 'react';
import { cn } from '@/utils/cn'; // Assuming you have a utility for classNames
import { Slider } from './Slider'; // Import Slider if it's in a different file
import { CarouselProvider, useCarouselContext } from './context';
import { SlidePage } from './SlidePage';
// import { SlideButton } from './SlideButton';

type AutoPlay = {
	delay: number;
};

type CarouselProps = React.HTMLProps<HTMLDivElement> & {
	children?: React.ReactNode;
	autoPlay?: AutoPlay;
};

const Carousel: React.FC<CarouselProps> = ({ children, autoPlay = { delay: 3000 }, ...props }) => {
	const { activeIndex, setSliderCount, setActiveIndex } = useCarouselContext();

	// Extract and count Slider components from children
	const sliders = React.Children.toArray(children).filter(
		(child) => React.isValidElement(child) && child.type === Slider
	);
	const sliderCount = sliders.length;

	// Set the slider count in context
	useEffect(() => {
		if (sliderCount > 0) setSliderCount(sliderCount);
	}, [sliderCount, setSliderCount]);

	useEffect(() => {
		if (sliderCount > 1 && autoPlay.delay) {
			const intervalId = setInterval(() => {
				setActiveIndex((prevIndex) => (prevIndex + 1) % sliderCount);
			}, autoPlay.delay);

			// Cleanup function to clear the interval
			return () => clearInterval(intervalId);
		}
	}, [activeIndex, autoPlay.delay, sliderCount]);

	return (
		<div className={cn('relative w-full overflow-hidden text-white', props.className)}>
			<div
				className="flex transition-transform duration-300 ease-in-out"
				style={{
					transform: `translateX(-${activeIndex * 100}%)`
				}}>
				{React.Children.toArray(children)
					.filter((child) => React.isValidElement(child) && child.type === Slider)
					.map((child, index) => (
						<div className="flex-shrink-0 w-full h-full" key={index}>
							{React.isValidElement(child) ? React.cloneElement(child) : child}
						</div>
					))}
			</div>
			<SlidePage />
		</div>
	);
};

export const CarouselWrapper: React.FC<CarouselProps> = ({ ...props }) => {
	return (
		<CarouselProvider>
			<Carousel {...props} />
		</CarouselProvider>
	);
};
