import { cn } from '@/utils/cn';
import { useCarouselContext } from './context';

type SlidePageProps = React.HTMLProps<HTMLDivElement> & {
	sliderCount?: number;
	activeIndex?: number;
};

export const SlidePage: React.FC<SlidePageProps> = ({ ...props }) => {
	const { sliderCount, activeIndex, setActiveIndex } = useCarouselContext();

	if (sliderCount > 1)
		return (
			<>
				<div
					id="slider-page"
					className={cn('flex w-full bottom-0 absolute justify-center z-10 pb-3', props.className)}>
					{Array.from({ length: sliderCount }, (_, i) => i + 1).map((item, index) => (
						<div
							key={item}
							className={`w-2 h-2 ${
								activeIndex == index ? 'bg-blue-600' : 'bg-slate-600'
							} rounded-full shadow-md mx-1 cursor-pointer`}
							onClick={() => setActiveIndex(index)}
						/>
					))}
				</div>
			</>
		);
};
