import React from 'react';

type SliderProps = React.HTMLProps<HTMLDivElement> & {
	children?: React.ReactNode;
};

export const Slider: React.FC<SliderProps> = ({ children }) => {
	return (
		<>
			<div className="w-full h-full inline-block">{children}</div>
		</>
	);
};
