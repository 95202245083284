const { create } = require('zustand');

const useWalletBalanceComponentStore = create((set) => ({
	openWalletBalanceModal: false,
	setToggleWalletBalanceModal: (openWalletBalanceModal) => {
		set({ openWalletBalanceModal: openWalletBalanceModal });
	},
	setOpenWalletBalanceModal: () => {
		set({ openWalletBalanceModal: true });
	},
	setCloseWalletBalanceModal: () => {
		set({ openWalletBalanceModal: false });
	}
}));

export default useWalletBalanceComponentStore;
