// import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import dynamic from 'next/dynamic';

import useRouterHook from '@/hooks/useRouterHook.hook';
import Header from '@/components/organism/Header/Header';
import useWindowHook from '@/hooks/useWindowHook.hook';
import { CategoryBanner, HomeBanner, PopupBanner } from '@/components/Banner';
const HeadLayout = dynamic(() => import('../templates/HeadLayout'), { ssr: true });
const ScriptLayout = dynamic(() => import('../templates/ScriptLayout'), { ssr: true });

const Sidebar = dynamic(() => import('../organism/Sidebar'), {
	ssr: false
});
const GameSearchPageComponent2 = dynamic(
	() => import('@/components/molecules/GameSearchComponent/components/GameSearchPageComponent2'),
	{
		ssr: false
	}
);
const NavbarMobile = dynamic(() => import('@/components/organism/NavbarMobile'), { ssr: false });
const LayoutModal = dynamic(() => import('../../features/modal/LayoutModal'), { ssr: false });
const Chat = dynamic(() => import('@/components/organism/Chat'), {
	ssr: false
});
const Footer = dynamic(() => import('@/components/organism/Footer'), { ssr: false });

interface LayoutPlayer {
	children?: React.ReactNode;
}

export const LayoutPlayer: React.FC<LayoutPlayer> = ({ children }) => {
	const { isMobile } = useWindowHook();
	const { router } = useRouterHook();
	return (
		<>
			<HeadLayout />
			<ScriptLayout />
			<div className="overflow-hidden relative flex flex-col w-full items-center">
				<Header />
				<Chat />
				{router.asPath === '/' && <PopupBanner />}
				{isMobile ? <NavbarMobile /> : null}
				{router.asPath.startsWith('/category/') && <CategoryBanner />}
				{router.asPath === '/' && <HomeBanner />}
				<div className="p-2 relative w-full flex flex-col flex-grow xl:max-w-7xl">{children}</div>
				<Sidebar />
				<GameSearchPageComponent2 />
				<LayoutModal />
				<Footer />
			</div>

			{/* {Array.from({ length: 300 }).map((item, index) => (
				<p key={index}>asdfasdf{index}</p>
			))} */}
		</>
	);
};
