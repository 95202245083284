'use client';
import { useMemo } from 'react';
import useAuthStore from './useAuthStore';
import useUserStore from '../user/userInfo/useUserStore';
// import BrandSetting from '../../../public/data/brandSettings.json';
import authService from './auth.service';
import FingerprintHelper from '@/helpers/fingerprint.helper';
import localStorageUtils from '@/utils/localStorage.utils';
import useBrandConfigStore from '../../../context/store/useBrandConfigStore.store';
import useUser from '../user/userInfo/useUser';

export default function useAuth() {
	// const brandId = BrandSetting.brandId;
	const { brandId } = useBrandConfigStore((state) => ({
		brandId: state.brandId
	}));

	const authStore = useAuthStore((state) => state);
	const userStore = useUserStore((state) => state);
	const { setLoggedInUser } = useUser();
	const { sessionToken, loginToken, fingerprint, isSessionValid } = authStore;

	const isLoggedIn = useMemo(() => {
		return loginToken && sessionToken && loginToken !== '' && sessionToken !== '';
	}, [loginToken, sessionToken]);

	// this function must be called in a useEffect in _app
	async function initializeAuthStore() {
		const fingerprint =
			localStorageUtils.getFingerprint() ||
			(await FingerprintHelper.GenerateFingerprint([
				'userAgent',
				'language',
				'colorDepth',
				'cookieEnabled',
				'deviceMemory',
				'doNotTrack',
				'screenResolution',
				'vendor'
			]));

		const oldSessionToken = localStorageUtils.getSessionToken();

		const isOldSessionTokenValid = await authService
			.verifySessionToken(brandId, fingerprint, oldSessionToken)
			.catch(() => {
				return false;
			});

		const sessionToken = isOldSessionTokenValid
			? oldSessionToken
			: await authService.getSessionToken(brandId, fingerprint);

		const oldLoginToken = localStorageUtils.getLoginToken();

		let isOldLoginTokenValid = false;
		if (oldLoginToken) {
			const verifyLoginToken = await authService.verifyLoginToken(oldLoginToken);
			isOldLoginTokenValid = verifyLoginToken.errorCode === 0;

			if (isOldLoginTokenValid) {
				userStore.setUser(verifyLoginToken.data.userData);
			}
		}

		const loginToken = isOldLoginTokenValid ? oldLoginToken : null;

		authStore.validationSession();
		authStore.initializeStore(loginToken, sessionToken, fingerprint);
	}

	async function login(data) {
		if (!sessionToken || !fingerprint) {
			return Promise.reject('No session token or fingerprint found');
		}

		const { username, password } = data;
		const payload = {
			brandId: brandId,
			username: username,
			password: password
		};

		return authService.login(payload, sessionToken).then((data) => {
			const loginToken = data.playerToken;

			authStore.login(loginToken);
			return loginToken;
		});
	}

	async function loginTransferToken(loginToken) {
		try {
			await setLoggedInUser(loginToken);
			authStore.login(loginToken);
		} catch (error) {
			console.error(error);
		}
	}

	async function register(payload) {
		if (!sessionToken) {
			return Promise.reject('No session token or fingerprint found');
		}
		return authService
			.register(payload, sessionToken)
			.then((response) => {
				const loginToken = response.data.playerToken;

				authStore.register(loginToken);
				return loginToken;
			})
			.catch((e) => {
				throw e.response.data;
			});
	}

	async function logout() {
		return authService
			.logout(loginToken)
			.catch(() => {})
			.finally(() => {
				authStore.logout();
			});
	}

	return {
		initializeAuthStore,
		isLoggedIn,
		sessionToken,
		loginToken,
		isSessionValid,
		login,
		loginTransferToken,
		logout,
		register
	};
}
