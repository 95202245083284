import { FC } from 'react';

type LayoutPlayer = {
	children?: React.ReactNode;
};

export const Container: FC<LayoutPlayer> = ({ children }) => {
	return (
		<>
			<div className="p-2 relative w-full flex flex-col flex-grow xl:max-w-7xl">{children}</div>
		</>
	);
};
