// sessionStorageUtil.ts

interface SessionStorageItem<T> {
	value: T;
	expiration: number; // Timestamp in milliseconds
}

/**
 * Set an item in session storage with an optional expiration time.
 * @param {string} key - The key under which to store the item.
 * @param {any} value - The value to store; will be JSON-stringified.
 * @param {number} [expiresAtMidnight] - The expiration time in minutes.
 */
export const setSessionItemExpiresAtMidnight = <T>(
	key: string,
	value: T,
	expiresAtMidnight?: boolean
): void => {
	if (typeof window !== 'undefined') {
		try {
			let expiration = null;
			if (expiresAtMidnight) {
				const now = new Date();
				const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
				expiration = tomorrow.getTime(); // This gets the timestamp of the next day at 00:00:00
			}

			const item: SessionStorageItem<T> = { value, expiration };
			const serializedItem = JSON.stringify(item);
			window.sessionStorage.setItem(key, serializedItem);
		} catch (error) {
			console.error('Error saving to sessionStorage', error);
		}
	}
};

/**
 * Get an item from session storage, checking for expiration.
 * @param {string} key - The key of the item to retrieve.
 * @returns {any|null} - The parsed JSON value or null if not found, expired, or on error.
 */
export const getSessionItem = <T>(key: string): T | null => {
	if (typeof window !== 'undefined') {
		try {
			const serializedItem = window.sessionStorage.getItem(key);
			if (!serializedItem) return null;

			const item: SessionStorageItem<T> = JSON.parse(serializedItem);
			if (item.expiration && new Date().getTime() > item.expiration) {
				window.sessionStorage.removeItem(key);
				return null;
			}

			return item.value;
		} catch (error) {
			console.error('Error reading from sessionStorage', error);
			return null;
		}
	}
	return null;
};

/**
 * Remove an item from session storage.
 * @param {string} key - The key of the item to remove.
 */
export const removeSessionItem = (key: string): void => {
	if (typeof window !== 'undefined') {
		try {
			window.sessionStorage.removeItem(key);
		} catch (error) {
			console.error('Error removing item from sessionStorage', error);
		}
	}
};

/**
 * Check if an item exists in session storage and is not expired.
 * Uses generics to specify the type of the item stored.
 * @param {string} key - The key of the item to check.
 * @returns {boolean} - True if the item exists and is not expired, false otherwise.
 */
export const hasSessionItem = <T>(key: string): boolean => {
	if (typeof window !== 'undefined') {
		try {
			const serializedItem = window.sessionStorage.getItem(key);
			if (!serializedItem) return false;

			const item: SessionStorageItem<T> = JSON.parse(serializedItem);
			if (item.expiration && new Date().getTime() > item.expiration) {
				window.sessionStorage.removeItem(key);
				return false;
			}

			return true;
		} catch (error) {
			console.error('Error accessing sessionStorage', error);
			return false;
		}
	}
	return false;
};
