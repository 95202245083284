import { useMemo } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { CarouselWrapper, Slider } from '@/components/Carousel/index';
import { customImageLoader } from '@/helpers/custom-image-loader';
import useBannerStore from '@/context/store/useBannerStore.store';
import useWindowHook from '@/hooks/useWindowHook.hook';

export const HomeBanner = () => {
	const { isMobile } = useWindowHook();
	const { homeBanner } = useBannerStore((state) => ({
		homeBanner: state.homeBanner
	}));

	const banner = useMemo(() => {
		if (isMobile !== null) {
			const selectedBanner = isMobile ? homeBanner.mobile : homeBanner.desktop;
			return selectedBanner.length
				? selectedBanner.map((item, index) => {
						const imageComponent = (
							<div className="relative flex items-center justify-center w-full aspect-2/1 lg:aspect-[3/1] max-h-[600px]">
								<Image
									src={item?.img}
									loader={customImageLoader}
									layout="fill"
									objectFit="contain"
									priority
									unoptimized
								/>
							</div>
						);
						if (item?.redirect) {
							return (
								<Slider key={index}>
									<Link href={item?.redirect}>
										<a target="_blank">{imageComponent}</a>
									</Link>
								</Slider>
							);
						} else {
							return <Slider key={index}>{imageComponent}</Slider>;
						}
				  })
				: null;
		}
	}, [isMobile]);

	return (
		<>
			<CarouselWrapper className="aspect-2/1 lg:aspect-[3/1] max-h-[600px] bg-black">
				{/* {banner.map((item, index) => (
					<Slider key={index}>
						<div className="relative flex items-center justify-center w-full aspect-2/1 lg:aspect-[3/1] max-h-[600px]">
							<Image
								src={item?.img}
								loader={customImageLoader}
								layout="fill"
								objectFit="contain"
								priority
							/>
						</div>
					</Slider>
				))} */}
				{banner}
			</CarouselWrapper>
		</>
	);
};
