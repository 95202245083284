import { useEffect, useMemo, useState } from 'react';

// Debounce function to limit the rate of function execution
function debounce(func, wait) {
	let timeout;
	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
}

const useWindowHook = () => {
	const [screenWidth, setScreenWidth] = useState(0);

	useEffect(() => {
		// Only execute in client-side environment
		if (typeof window !== 'undefined') {
			const handleResize = () => {
				setScreenWidth(window.innerWidth);
			};

			const debouncedHandleResize = debounce(handleResize, 100); // Debounce resizing

			window.addEventListener('resize', debouncedHandleResize);

			// Initial setting of screenWidth
			handleResize();

			return () => window.removeEventListener('resize', debouncedHandleResize);
		}
	}, []);

	const isMobile = useMemo(() => {
		if (screenWidth === 0) {
			return null;
		}
		return screenWidth < 1024;
	}, [screenWidth]);

	const closeWindowTabWithTimeout = () => {
		setTimeout(() => {
			window.opener = null;
			window.open('', '_self');
			window.close();
		}, 8000);
	};

	return { screenWidth, isMobile, closeWindowTabWithTimeout };
};

export default useWindowHook;
