const { create } = require('zustand');

const useSidebarStore = create((set) => ({
	openSidebar: false,
	setOpenSidebar: () => {
		set({ openSidebar: true });
	},
	setCloseSidebar: () => {
		set({ openSidebar: false });
	}
}));

export default useSidebarStore;
