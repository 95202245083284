import React, { createContext, useState, useContext, ReactNode } from 'react';

// Context type
type CarouselContextType = {
	sliderCount: number;
	setSliderCount: (value: number | ((prevCount: number) => number)) => void;
	activeIndex: number;
	setActiveIndex: (value: number | ((prevIndex: number) => number)) => void;
};

// Default values for context
const defaultContextValue: CarouselContextType = {
	sliderCount: 0,
	setSliderCount: () => {},
	activeIndex: 0,
	setActiveIndex: () => {}
};

// Create context
const CarouselContext = createContext(defaultContextValue);

// Context provider
export const CarouselProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [sliderCount, setSliderCount] = useState(0);
	const [activeIndex, setActiveIndex] = useState(0);

	return (
		<CarouselContext.Provider value={{ sliderCount, setSliderCount, activeIndex, setActiveIndex }}>
			{children}
		</CarouselContext.Provider>
	);
};

// Custom hook to use the context
export const useCarouselContext = () => {
	const context = useContext(CarouselContext);
	if (!context) {
		throw new Error('useCarouselContext must be used within a CarouselProvider');
	}
	return context;
};
