import useBannerStore from '@/context/store/useBannerStore.store';
import { getSessionItem, setSessionItemExpiresAtMidnight } from '@/utils/sessionStorage.utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdClose } from 'react-icons/io';

type PopupSetting = {
	isActive: boolean;
};

export const PopupBanner = () => {
	const { t } = useTranslation();
	const [isShow, setIsShow] = useState(true);
	const [showBanner, setShowBanner] = useState({
		isActive: false,
		img: ''
	});
	const { popupBanner } = useBannerStore((state) => ({
		popupBanner: state.popupBanner
	}));

	useEffect(() => {
		const popupSetting: PopupSetting = getSessionItem('showpopup');
		if (popupSetting) {
			setShowBanner({
				isActive: popupSetting.isActive,
				img: popupBanner?.bannerPopUp?.img
			});
		} else {
			setShowBanner({
				isActive: popupBanner?.bannerPopUp?.isActive === 'active' ? true : false,
				img: popupBanner?.bannerPopUp?.img
			});
			setSessionItemExpiresAtMidnight('showpopup', {
				isActive: popupBanner?.bannerPopUp?.isActive === 'active' ? true : false
			});
		}
	}, [popupBanner]);

	const onClickNotShowAgain = () => {
		setIsShow(!isShow);
	};

	const onCloseHanlder = () => {
		setSessionItemExpiresAtMidnight('showpopup', { isActive: isShow }, true);
		setShowBanner((prev) => ({ ...prev, isActive: false }));
	};

	if (!showBanner.isActive) {
		return null;
	}

	return (
		<>
			<div className="flex fixed w-full h-full inset-0 z-200 justify-center items-center bg-black/50">
				<div className="relative max-w-7xl m-4 lg:m-10 px-2 pt-2 bg-container-bg-primary">
					<div
						className="absolute -right-3 -top-3 flex items-center justify-center w-7 h-7 rounded-full bg-container-bg-primary"
						onClick={onCloseHanlder}>
						<IoMdClose className="w-4 h-4 aspect-square text-container-color-primary" />
					</div>
					<div className="flex w-full h-full items-center justify-center">
						<img src={popupBanner?.bannerPopUp?.img} alt="" className="w-full h-full" />
					</div>
					<div className="flex flex-row gap-2 items-center">
						<input
							id="check-popupbanner"
							type="checkbox"
							className=""
							onClick={onClickNotShowAgain}
						/>
						<label htmlFor="check-popupbanner">{t('text.popupbannerdontshow')}</label>
					</div>
				</div>
			</div>
		</>
	);
};
